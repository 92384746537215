'use client';

// https://next-intl-docs.vercel.app/docs/environments/error-files
import Link from 'next/link';

import { ROUTER_LINKS } from '@/shared/constants/links-constants';

export default function NotFound() {
  return (
    <html lang="en">
      <body>
        <div>
          <h2>Not Found</h2>
          <p>Could not find requested resource</p>
          <Link href={ROUTER_LINKS.home}>Return Home</Link>
        </div>
      </body>
    </html>
  );
}
